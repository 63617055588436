export const validationMessage = {
	required: 'Este campo é obrigatório',
	string: {
		required: 'Este campo é obrigatório',
		email: 'Este não é um email válido',
		url: 'Esta não é uma URL válida',
		min: (min: number) => `Este campo tem que ter no mínimo ${min} caracteres`,
		max: (max: number) => `Este campo tem que ter no máximo ${max} caracteres`,
	},
	number: {
		required: 'Este campo é obrigatório',
		typeError: 'Este campo é do tipo número',
	},
};

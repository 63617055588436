import React, { useEffect, useCallback } from 'react';
import {
	Routes, Route, Navigate, useLocation, useNavigate,
} from 'react-router-dom';
// import Inventory from "./components/Inventory/Inventory";
// import InventoryContainer from "./components/Inventory/InventoryContainer";
import Login from './containers/Login';
import Home from './containers/Home';
import Provider from './containers/Provider/Provider';
import ProviderEdit from './containers/Provider/ProviderEdit';
import Client from './containers/Client/Client';
import UserContainer from './containers/User/User';
import ClientEdit from './containers/Client/ClientEdit';
import UserEditContainer from './containers/User/UserEdit';
import PurchaseOrderApproval from './containers/Order/PurchaseOrderApproval';
import PurchaseOrder from './containers/Order/PurchaseOrder';
import BudgetDashboard from './containers/Dashboards/BudgetDashboard';
import BudgetUsers from './containers/Budget/BudgetUsers';

import { isAuthenticated } from './services/auth';
import DefaultLayout from './components/Common/DefaultLayout';
import { UnauthorizedPage } from './components/Common/UnauthorizedPage';
import PageNotFound from './components/Common/PageNotFound';
import { hasPermission } from './helpers/permission';
import BudgetResponsible from './containers/Budget/BudgetResponsible';
import { getMenu } from './services/app';
import { Role } from './interfaces/Module';

const RequireAuth = ({ children, redirectTo }: { [x: string]: any }): JSX.Element => {
	const location = useLocation();

	return isAuthenticated() ? (
		children
	) : (
		<Navigate to={redirectTo} state={{ from: location }} />
	);
};

const ProtectedRoute = ({
	children, module, edit, admin,
}: {
	[x: string]: any
} & Role): JSX.Element => (
	hasPermission({ module, edit, admin }) ? (children) : (<UnauthorizedPage />)
);

ProtectedRoute.defaultProps = {
	edit: false,
	admin: false,
};

const ValidateRoute = ({
	children, redirectTo, module, edit, admin,
}: { [x: string]: any } & Role): JSX.Element => {
	const location = useLocation();

	if (!isAuthenticated()) {
		return <Navigate to={redirectTo} state={{ from: location }} />;
	}

	if (!hasPermission({ module, edit, admin })) {
		return <UnauthorizedPage />;
	}

	return children;
};

ValidateRoute.defaultProps = {
	edit: false,
	admin: false,
};

const RoutesComponent = ({ name }: { name: string }): JSX.Element => {
	const location = useLocation();
	const navigate = useNavigate();
	const logout = useCallback(() => navigate('/login'), [navigate]);

	useEffect(() => {
		const currentModule = getMenu().find((module) => location.pathname.includes(module.link));
		document.title = currentModule ? `${currentModule?.name} | ${name}` : name;
	}, [location, name]);

	useEffect(() => {
		window.addEventListener('logout', logout, false);

		return () => {
			window.removeEventListener('logout', logout, false);
		};
	}, [logout]);

	return (
		<Routes>
			<Route path="/login" element={<Login />} />
			<Route path="/signup" element={<h1>SignUp</h1>} />
			<Route element={<DefaultLayout />}>
				<Route
					path=""
					element={(
						<RequireAuth redirectTo="/login">
							<Home />
						</RequireAuth>
					)}
				/>
				<Route path="provider">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="Provider">
								<Provider />
							</ValidateRoute>
						)}
					/>
					<Route path="edit">
						<Route
							path=""
							element={(
								<ValidateRoute redirectTo="/login" module="PROVIDER" edit>
									<ProviderEdit />
								</ValidateRoute>
							)}
						/>
						<Route
							path=":id"
							element={(
								<ValidateRoute redirectTo="/login" module="PROVIDER" edit>
									<ProviderEdit />
								</ValidateRoute>
							)}
						/>
					</Route>
				</Route>
				<Route path="client">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="CLIENT">
								<Client />
							</ValidateRoute>
						)}
					/>
					<Route path="edit">
						<Route
							path=""
							element={(
								<ValidateRoute redirectTo="/login" module="CLIENT" edit>
									<ClientEdit />
								</ValidateRoute>
							)}
						/>
						<Route
							path=":id"
							element={(
								<ValidateRoute redirectTo="/login" module="CLIENT" edit>
									<ClientEdit />
								</ValidateRoute>
							)}
						/>
					</Route>
				</Route>
				<Route path="user">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="USER">
								<UserContainer />
							</ValidateRoute>
						)}
					/>
					<Route path="edit">
						<Route
							path=""
							element={(
								<ValidateRoute redirectTo="/login" module="USER" edit>
									<UserEditContainer />
								</ValidateRoute>
							)}
						/>
						<Route
							path=":id"
							element={(
								<ValidateRoute redirectTo="/login" module="USER" edit>
									<UserEditContainer />
								</ValidateRoute>
							)}
						/>
					</Route>
				</Route>
				<Route path="budget">
					<Route
						path=""
						element={(
							<ValidateRoute redirectTo="/login" module="BUDGET">
								<BudgetDashboard />
							</ValidateRoute>
						)}
					/>
					<Route path="users">
						<Route
							index
							element={(
								<ValidateRoute redirectTo="/login" module="BUDGET" edit>
									<BudgetUsers />
								</ValidateRoute>
							)}
						/>
						<Route path="responsible">
							<Route
								path=":userId"
								element={(
									<ValidateRoute redirectTo="/login" module="BUDGET" edit>
										<BudgetResponsible />
									</ValidateRoute>
								)}
							/>
							<Route path="*" element={<PageNotFound />} />
						</Route>
					</Route>
				</Route>
				<Route path="order">
					<Route
						index
						element={(
							<ValidateRoute redirectTo="/login" module="ORDER" edit>
								<PurchaseOrderApproval manage />
							</ValidateRoute>
						)}
					/>
					<Route
						path="approval"
						element={(
							<ValidateRoute redirectTo="/login" module="ORDER" edit>
								<PurchaseOrderApproval />
							</ValidateRoute>
						)}
					/>
					<Route
						path="apportionment/:id"
						element={(
							<ValidateRoute redirectTo="/login" module="ORDER" edit>
								<PurchaseOrder apportionment />
							</ValidateRoute>
						)}
					/>
					<Route
						path="apportionment"
						element={(
							<ValidateRoute redirectTo="/login" module="ORDER" edit>
								<PurchaseOrder />
							</ValidateRoute>
						)}
					/>
				</Route>
				<Route path="*" element={<PageNotFound />} />
			</Route>
		</Routes>
	);
};

export default RoutesComponent;

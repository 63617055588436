import React, { ReactNode, useMemo } from 'react';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DrawerHeader from './DrawerHeader';

interface IGenericDrawerProps extends DrawerProps {
  open: boolean;
  title: string;
  subtitle?: string;
  width?: number;
  content: ReactNode;
  onClose: () => void;
}

const GenericDrawer = ({
	open,
	title,
	subtitle,
	width,
	content,
	onClose,
}: IGenericDrawerProps): JSX.Element => {
	const headerMemo = useMemo(() => (
		<Stack direction="row" alignItems="center" justifyContent="space-between" p={2}>
			<Stack direction="column" spacing={1}>
				<Typography variant="h6" color="primary.main">{title}</Typography>
				{ subtitle && <Typography variant="subtitle1" color="primary.main">{subtitle}</Typography> }
			</Stack>
			<IconButton onClick={onClose}><CloseIcon /></IconButton>
		</Stack>

	), [onClose, subtitle, title]);

	return (
		<Drawer
			anchor="right"
			open={open}
			onClose={onClose}
			PaperProps={{
				sx: {
					minWidth: width,
					width,
				},
			}}
			sx={{
				overflow: 'hidden',
			}}
		>
			<DrawerHeader />
			{headerMemo}
			<Box sx={{
				p: 2, width: '100%', height: '100%', overflow: 'auto',
			}}
			>
				{content}
			</Box>
		</Drawer>
	);
};

GenericDrawer.defaultProps = {
	width: 500,
	subtitle: null,
};

export default GenericDrawer;

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import styled from '@mui/system/styled';
import FormHelperText from '@mui/material/FormHelperText';

const StyledDialog = styled(Dialog)`
.MuiDialog-paper {
    width: 400px;
    height: 370px;
  }
`;

const justificativeMaxLength = 100;

interface ActionJustificativeModalProps {
	id: string;
	title: string;
	action?: (id: string, justificative: string) => void;
	open: boolean;
	onClose: () => void;
}

const ActionJustificativeModal: React.FC<ActionJustificativeModalProps> = ({
	id, title, action, open, onClose,
}) => {
	const [justificative, setJustificative] = useState('');

	const handlejustificativeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setJustificative(event.target.value);
	};

	const handleSubmit = (): void => {
		action?.(id, justificative);
		onClose();
		setJustificative('');
	};

	return (
		<StyledDialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Por favor, insira a sua justificativa abaixo.
				</DialogContentText>
				<TextField
					autoFocus
					margin="dense"
					id="justificative"
					label="Justificativa"
					placeholder="Justificativa..."
					type="text"
					multiline
					rows={6}
					fullWidth
					value={justificative}
					onChange={handlejustificativeChange}
					inputProps={{
						maxLength: justificativeMaxLength,
						style: { fontSize: '18px' },
					}}
					InputLabelProps={{
						style: { fontSize: '17px' },
					}}
				/>
				<FormHelperText>
					{`${justificativeMaxLength - justificative.length} caracteres restantes`}
				</FormHelperText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary" variant="outlined">
					Cancelar
				</Button>
				<Button onClick={handleSubmit} color="primary" variant="contained" disabled={!justificative.trim()}>
					Enviar
				</Button>
			</DialogActions>
		</StyledDialog>
	);
};

ActionJustificativeModal.defaultProps = {
	action: undefined,
};

export default ActionJustificativeModal;

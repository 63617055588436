import React, {
	useCallback, useEffect, useMemo,
} from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PieChartIcon from '@mui/icons-material/PieChart';
import { Link } from 'react-router-dom';
import { DashboardFilter } from '../../../containers/Dashboards/BudgetDashboardAssets';
import { defaultCardSpacing } from '../../../constants/dashboard';
import { Loading } from '../../Common/Loading';
import { PageHeader } from '../../Common/PageHeader/PageHeader';
import { BudgetSummaryCards } from './BudgetSummaryCards';
import { BudgetPieCharts } from './BudgetPieCharts';
import { BudgetDataGrid } from './BudgetDataGrid';
import { UploadBudgetDialog } from './UploadBudgetDialog';
import { BudgetDashboardFilters } from './DashboardFilters/BudgetDashboardFilters';
import { UserTypeSelector } from '../../Order/UserManagement/UserTypeSelector';
import { UserType } from '../../../containers/Order/PurchaseOrderApproval';
import NoDataPage from '../../Common/NoDataPage';
import ManageBudgetValueTransferModal from './ManageBudgetValueTransferModal';
import { IManageValueTransferData, IValueTransfer } from '../../../interfaces/BudgetValueTransfer';
import { PageHeaderButtonProps } from '../../../interfaces/PageHeaderInterface';

interface DashboardProps {
	loading: boolean;
	budgets: any;
	budgetData: any;
	uploading: boolean;
	showUploadModal: boolean;
	dashboardFilter: DashboardFilter;
	selectedVision: any;
	filterFieldsValues: any;
	userTypes: UserType[];
	purchasesCount: number;
	showManageValueTransferModal: boolean;
	valueTransfers: IValueTransfer[];
	setShowManageValueTransferModal(showManageValueTransferModal: boolean): void;
	getFilterFieldsValues: (type?: UserType) => void;
	uploadBudgetFile(formData: FormData): any;
	setShowUploadModal(loading: boolean): void;
	setSelectedVision(selectedVision: any): void;
	handleDashboardFilter(filter: DashboardFilter, vision: string, type?: UserType): void;
	getValueTransfers(userType?: UserType): void;
	getDetailedBudgetVision(filterData: any, vision: string, type?: UserType): void;
	getUserTypes(): void;
	getPurchasesCount(userType?: UserType): void,
	sendManageValueTransfer(data: IManageValueTransferData): void;
}

const Dashboard = ({
	loading,
	budgets,
	budgetData,
	uploading,
	showUploadModal,
	dashboardFilter,
	selectedVision,
	filterFieldsValues,
	userTypes,
	purchasesCount,
	valueTransfers,
	showManageValueTransferModal,
	setShowManageValueTransferModal,
	getFilterFieldsValues,
	setShowUploadModal,
	uploadBudgetFile,
	handleDashboardFilter,
	getDetailedBudgetVision,
	setSelectedVision,
	getUserTypes,
	getPurchasesCount,
	getValueTransfers,
	sendManageValueTransfer,
}: DashboardProps): JSX.Element => {
	const [userType, setUserType] = React.useState(0);

	useEffect(() => {
		getFilterFieldsValues();
		getUserTypes();
		getPurchasesCount();
		handleDashboardFilter(dashboardFilter, selectedVision.value);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setUserType(Math.max(...userTypes));
	}, [userTypes]);

	const openUploadModal = useCallback(
		() => setShowUploadModal(true),
		[setShowUploadModal],
	);

	const openManageBudgetValueTransferModal = useCallback(
		() => setShowManageValueTransferModal(true),
		[setShowManageValueTransferModal],
	);

	const closeManageBudgetValueTransferModal = useCallback(() => {
		setShowManageValueTransferModal(false);
	}, [setShowManageValueTransferModal]);

	const handleDataGridVisionChange = useCallback((vision: any) => {
		setSelectedVision(vision);
		getDetailedBudgetVision(dashboardFilter, vision.value, userType);
	}, [dashboardFilter, getDetailedBudgetVision, setSelectedVision, userType]);

	const handleDashboardFilterProxy = useCallback((filter: DashboardFilter) => {
		handleDashboardFilter(filter, selectedVision.value, userType);
	}, [handleDashboardFilter, selectedVision.value, userType]);

	const handleChangeUserType = useCallback((value: number) => {
		setUserType(value);
		handleDashboardFilter(dashboardFilter, selectedVision.value, value);
		getFilterFieldsValues(value);
		getPurchasesCount(value);
	}, [
		handleDashboardFilter,
		dashboardFilter,
		selectedVision.value,
		getFilterFieldsValues,
		getPurchasesCount,
	]);

	const pageHeaderMemo = useMemo(() => {
		const headerButtonsProps: PageHeaderButtonProps[] = [
			{
				component: Link,
				variant: 'contained',
				to: '/order/apportionment',
				startIcon: <PieChartIcon />,
				text: 'Gerenciar rateios',
				show: userType === UserType.CONTROLLERSHIP,
			},
			{
				component: Link,
				variant: 'contained',
				to: '/order/approval',
				startIcon: <PriceCheckIcon />,
				text: 'Gerenciar pedidos',
				show: true,
				badgeContent: purchasesCount.toString(),
			},
			{
				text: 'Gerenciar transferências de saldo',
				variant: 'contained',
				onClick: openManageBudgetValueTransferModal,
				show: userType !== UserType.RESPONSIBLE,
			},
			{
				component: Link,
				variant: 'contained',
				color: 'primary',
				to: 'users',
				text: 'Editar usuários',
				startIcon: <ManageAccountsIcon />,
				show: userType === UserType.CONTROLLERSHIP,
			},
			{
				component: Link,
				variant: 'contained',
				to: '/order',
				startIcon: <SettingsIcon />,
				text: 'Controle de Pedidos',
				show: userType === UserType.CONTROLLERSHIP,
			},
			{
				variant: 'contained',
				startIcon: <UploadFileIcon />,
				onClick: openUploadModal,
				text: 'Importar',
				show: userType === UserType.CONTROLLERSHIP,
			},
		];

		return <PageHeader title="Dashboard" buttons={headerButtonsProps} loading={userType === -Infinity} />;
	}, [openManageBudgetValueTransferModal, openUploadModal, purchasesCount, userType]);

	const dashboardFiltersMemo = useMemo(() => (
		<BudgetDashboardFilters
			filterFieldsValues={filterFieldsValues}
			handleDashboardFilter={handleDashboardFilterProxy}
			dashboardFilter={dashboardFilter}
		/>
	), [dashboardFilter, filterFieldsValues, handleDashboardFilterProxy]);

	const summaryCardsMemo = useMemo(() => (
		budgets
		&& (
			<BudgetSummaryCards
				budgeted={budgets.totalValue}
				realized={budgets.realizedValue}
				committed={budgets.commitedValue}
				toApprove={budgets.toCommitValue}
				balance={budgets.balance}
			/>
		)
	), [budgets]);

	const pieChartsMemo = useMemo(() => (
		budgets
		&& (
			<BudgetPieCharts
				total={budgets.totalValue}
				realized={budgets.realizedValue}
				committed={budgets.commitedValue}
				toApprove={budgets.toCommitValue}
				balance={budgets.balance}
			/>
		)
	), [budgets]);

	const dataGridMemo = useMemo(() => (
		<BudgetDataGrid
			budgetData={budgetData}
			selectedVision={selectedVision}
			handleDataGridVisionChange={handleDataGridVisionChange}
		/>
	), [budgetData, selectedVision, handleDataGridVisionChange]);

	const hasNoData = useMemo(() => (
		budgets?.balance === '0'
			&& budgets?.commitedValue === '0'
			&& budgets?.realizedValue === '0'
			&& budgets?.toCommitValue === '0'
			&& budgets?.totalValue === '0'
	), [
		budgets?.balance,
		budgets?.commitedValue,
		budgets?.realizedValue,
		budgets?.toCommitValue,
		budgets?.totalValue,
	]);

	const contentMemo = useMemo(() => {
		if (loading) {
			return <Loading />;
		}

		if (hasNoData) {
			return <NoDataPage />;
		}

		return (
			<>
				{summaryCardsMemo}
				{pieChartsMemo}
				{dataGridMemo}
			</>
		);
	}, [dataGridMemo, hasNoData, loading, pieChartsMemo, summaryCardsMemo]);

	return (
		<>
			{pageHeaderMemo}
			<Stack spacing={defaultCardSpacing}>
				<Box>
					<UserTypeSelector
						userTypes={userTypes}
						userType={userType}
						onChange={handleChangeUserType}
					/>
				</Box>
				<Stack spacing={defaultCardSpacing}>
					{dashboardFiltersMemo}
					{contentMemo}
				</Stack>
			</Stack>
			<UploadBudgetDialog
				showUploadModal={showUploadModal}
				setShowUploadModal={setShowUploadModal}
				uploadBudgetFile={uploadBudgetFile}
				uploading={uploading}
			/>
			<ManageBudgetValueTransferModal
				userType={userType}
				showManageValueTransferModal={showManageValueTransferModal}
				closeManageBudgetValueTransferModal={closeManageBudgetValueTransferModal}
				getValueTransfers={getValueTransfers}
				valueTransfers={valueTransfers}
				sendManageValueTransfer={sendManageValueTransfer}
			/>
		</>
	);
};

export default Dashboard;

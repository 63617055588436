import { AxiosResponse } from 'axios';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { DEFAULT_NAME } from '../constants/general';
import { Cep } from '../interfaces/cep';
import { IModule, IMenu } from '../interfaces/Module';
import api from './api';

export const decode: any = (token: string) => jwtDecode<JwtPayload>(token);

export const getTokenDecoded = (): any => localStorage.getItem('token') && decode(localStorage.getItem('token'));

const NOT_MENU = [
	'BUDGET_APPROVER',
	'BUDGET_RESPONSIBLE',
	'BUDGET_VALUE_TRANSFER',
	'ORDER',
];

export const getModules = (): IModule[] => {
	const permissions = localStorage.getItem('permissions');

	if (permissions) {
		return JSON.parse(permissions);
	}

	return [];
};

export const getMenu = (): IMenu[] => {
	const permissions = getModules();

	if (permissions.length) {
		return permissions
			.filter((permission) => !NOT_MENU.includes(permission.module.code))
			.map((permission) => permission.module);
	}

	return [];
};

export const getConfiguration = (): Promise<AxiosResponse> => api.get('/configuration');

export const getName = (): string => localStorage.getItem('name') || DEFAULT_NAME;

export const getAddressByCep = (
	cep: number,
): Promise<AxiosResponse<Cep>> => api.get<{ cep: number }, AxiosResponse<Cep>>(
	`https://viacep.com.br/ws/${cep}/json/`,
);

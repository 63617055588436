import React, { MouseEvent } from 'react';
import {
	DebouncedFunc, debounce as _debounce, omit, pickBy,
} from 'lodash';
import {
	GridColDef,
	GridSortDirection,
	GridSortModel,
	GridValueFormatterParams,
} from '@mui/x-data-grid';
import { AxiosResponse } from 'axios';

export const getNumbersFromPhone = (phone: string): string => {
	let numbers = phone;
	numbers = numbers.replace('(', '');
	numbers = numbers.replace(')', '');
	numbers = numbers.replaceAll(' ', '');
	numbers = numbers.replace('-', '');

	return numbers;
};

export const stopPropagation = (e: MouseEvent<HTMLElement>): void => e.stopPropagation();

export const debounce = (
	func: (...args: any) => any,
	wait?: number,
	options?: {
    leading?: boolean | undefined;
    maxWait?: number | undefined;
    trailing?: boolean | undefined;
  },
): DebouncedFunc<(...args: any
) => any> => _debounce(func, wait || 300, options);

export const getBoundaryYears = (): { minYear: Date; maxYear: Date } => {
	const minYear = new Date();
	const maxYear = new Date();

	minYear.setFullYear(minYear.getFullYear() - 5);
	maxYear.setFullYear(maxYear.getFullYear() + 5);

	return { minYear, maxYear };
};

export const getFirstDayOfCurrentMonth = (): Date => {
	const date = new Date();
	date.setDate(1);
	date.setHours(0, 0, 0, 0);
	return date;
};

export const normalizeDataGridOrderBy = (
	sortModel: GridSortModel,
): { [x: string]: GridSortDirection } | null => {
	if (sortModel.length === 0) {
		return null;
	}

	const { field, sort } = sortModel[0];

	return { [field]: sort };
};

export function filterObject<T extends Record<string, any>>(
	obj: T,
	omitItems: (keyof T)[] = [],
	pickFunction: (value: any) => boolean = (value) => Number.isInteger(value) || typeof value === 'boolean' || Boolean(value),
): Partial<T> {
	let result: Partial<T> = obj;
	if (omitItems.length > 0) {
		result = omit(obj, ...omitItems) as Partial<T>;
	}
	return pickBy(result, pickFunction) as Partial<T>;
}

type Formatter<T> = (value: T) => string;

export const formatValueOrNA = (
	params: GridValueFormatterParams,
	formatter: Formatter<any> = (value: any) => value.toString(),
): string => (params.value ? formatter(params.value) : 'N/A');

export const getStoredFilterWithDates = <T>(
	localStorageKey: string,
	defaultValues?: T,
): T => {
	const storedValues = localStorage.getItem(localStorageKey);
	let storedFilter: T = defaultValues || ({} as T);

	if (storedValues) {
		const parsedValues: T = JSON.parse(storedValues);
		storedFilter = { ...storedFilter, ...parsedValues };
	}

	return storedFilter;
};

export const formatOrRenderValue = (
	column: GridColDef,
	value: any,
): React.ReactNode => {
	const params = { field: column.field, value };
	const formatter = column.valueFormatter || column.valueGetter || column.renderCell;

	if (formatter) {
		const formattedValue = formatter(params as any);
		return (
			(formattedValue as React.ReactElement).props?.value
      ?? formattedValue
      ?? 'N/A'
		);
	}

	return value;
};

export const downloadFile = (response: AxiosResponse): void => {
	const cotentDisposition = response.headers['content-disposition'];
	const link = document.createElement('a');
	const url = URL.createObjectURL(response.data);

	link.setAttribute('download', cotentDisposition.split('filename=')[1].replaceAll('"', ''));
	link.href = url;
	document.body.appendChild(link);
	link.click();

	document.body.removeChild(link);
	URL.revokeObjectURL(url);
};

/* eslint-disable react/jsx-props-no-spreading */
import React, {
	useCallback, useState, useMemo, ReactElement,
} from 'react';
import {
	TextField,
	FormHelperText,
	Theme,
	TextFieldProps,
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	IconButton,
	FormControlLabel,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { useField } from 'formik';
import { IMaskInput, IMask } from 'react-imask';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import NumberFormat from 'react-number-format';

interface FinancialInputProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const InputField = ({
	name,
	defaultValue,
	helperText,
	...props
}: TextFieldProps): JSX.Element => {
	const [field, meta, helpers] = useField({
		name: name as string,
		defaultValue: defaultValue as string,
	});

	const helperTextMemo = useMemo(() => {
		if (Boolean(meta.touched) && Boolean(meta.error)) {
			return meta.error;
		}

		if (helperText) {
			return helperText;
		}

		return null;
	}, [meta.error, meta.touched, helperText]);

	return (
		<TextField
			{...props}
			{...field}
			fullWidth
			error={Boolean(meta.touched) && Boolean(meta.error)}
			helperText={helperTextMemo}
		/>
	);
};

const PasswordInputField = ({ margin, ...props }: TextFieldProps): JSX.Element => {
	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword =	(): void => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
		event.preventDefault();
	};

	return (
		<InputField
			{...props}
			type={showPassword ? 'text' : 'password'}
			margin={margin || 'normal'}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={() => { handleClickShowPassword(); }}
							onMouseDown={handleMouseDownPassword}
							edge="end"
						>
							{showPassword ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					</InputAdornment>
				),
			}}
		/>
	);
};

export const FinancialInput = React.forwardRef<NumberFormat<number>, FinancialInputProps>(
	(props, ref) => {
		const { onChange, ...other } = props;

		return (
			<NumberFormat
				{...other}
				getInputRef={ref}
				onValueChange={(values: any) => {
					onChange({
						target: {
							name: props.name,
							value: values.value,
						},
					});
				}}
				thousandSeparator="."
				decimalSeparator=","
				decimalScale={2}
				isNumericString
				prefix="R$ "
			/>
		);
	},
);

const FinancialInputField = (props: TextFieldProps): JSX.Element => (
	<InputField
		{...props}
		InputProps={{
			inputComponent: FinancialInput as any,
		}}
	/>
);

interface IMask {
	onChange:(event: { target: { name: string; value: string } }) => void;
	name: string;
	mask: any;
	definitions?: any;
	overwrite?: boolean;
}

const Mask = React.forwardRef<IMask.MaskElement, IMask>((props, ref) => {
	const {
		onChange, mask, definitions, overwrite, ...other
	} = props;

	return (
		<IMaskInput
			{...other}
			mask={mask}
			definitions={definitions}
			inputRef={ref}
			onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
			overwrite={overwrite}
		/>
	);
});

Mask.defaultProps = {
	definitions: null,
	overwrite: false,
};

interface IInputMaskField {
	mask: string | { mask: string }[] | any;
	definitions?: any | any[];
	overwrite?: boolean;
}

type TInputMaskField =
	& TextFieldProps
	& IInputMaskField;

const InputMaskField = ({
	InputProps,
	mask,
	definitions,
	overwrite,
	onBlur,
	...props
}: TInputMaskField): JSX.Element => (
	<InputField
		{...props}
		InputProps={{
			...InputProps,
			inputComponent: Mask as any,
			inputProps: {
				mask,
				definitions,
				overwrite,
			},
			onBlur,
		}}
	/>
);

InputMaskField.defaultProps = {
	definitions: null,
	overwrite: false,
};

export default {
	InputField: React.memo(InputField),
	InputMaskField: React.memo(InputMaskField),
	PasswordInputField: React.memo(PasswordInputField),
	FinancialInputField: React.memo(FinancialInputField),
};

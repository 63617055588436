/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { TextField, Autocomplete as MuiAutocomplete, AutocompleteProps } from '@mui/material';
import { useField } from 'formik';

interface IAutocompleteProps<T> extends Omit<AutocompleteProps<any, boolean, undefined, undefined, 'div'>, 'renderInput'> {
  options: T[];
  labelKey: keyof T;
  valueKey: keyof T;
  label: string;
  name: string;
  valueLabel?: keyof T;
  required?: boolean;
}

const Autocomplete = <T, >({
	options, labelKey, valueKey, valueLabel, label, name, required, ...props
}: IAutocompleteProps<T>): JSX.Element => {
	const [field, meta, helpers] = useField({ name });

	const optionsValues = useMemo(
		() => options.map((option) => option[valueKey]),
		[options, valueKey],
	);

	const error = useMemo(() => {
		if (Boolean(meta.touched) && Boolean(meta.error)) {
			return meta.error;
		}

		return null;
	}, [meta.error, meta.touched]);

	const handleChange = (e: any, newValues: any): void => {
		helpers.setValue(newValues);
	};

	return (
		<MuiAutocomplete
			{...field}
			autoHighlight
			disableCloseOnSelect
			filterSelectedOptions
			{...props}
			options={optionsValues}
			onChange={handleChange}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					placeholder={label}
					required={required}
					error={Boolean(error)}
					helperText={error}
				/>
			)}
			getOptionLabel={(option) => {
				const optionObject = options.find((item) => item[valueKey] === option);

				if (optionObject) {
					return `${valueLabel ? optionObject?.[valueLabel] : option} - ${optionObject?.[labelKey]}`;
				}

				return '';
			}}
			noOptionsText="Não há opções"
		/>
	);
};

Autocomplete.defaultProps = {
	valueLabel: null,
	required: false,
};

export default Autocomplete;

import React, { useMemo } from 'react';
import {
	GridColDef,
	GridRenderCellParams,
	GridColumnVisibilityModel,
	GridValueFormatterParams,
	GridActionsCellItem,
} from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import InfoIcon from '@mui/icons-material/Info';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Link } from 'react-router-dom';
import { defaultColumns } from '../constants/purchaseOrdersColumns';
import { purchaseOrderApprovalStatusDetailed } from '../constants/purchaseOrderApprovalStatus';
import TruncateTooltipCell from '../components/Common/Datagrid/TruncateTooltipCell';

type ColumnData = {
	columns: GridColDef[];
	defaultVisibility: GridColumnVisibilityModel;
};

type UseColumnsOptions = {
	isDetailed?: boolean;
	onApportionmentClick?: ((id: string) => void) | null;
	onDetailsClick?: ((id: string) => void) | null;
	columnsVisibility: string[];
	optionalColumnsVisibility?: string[];
	apportionAction?: boolean;
	onNFDownloadClick?: ((id: string, invoiceNumber?: string) => void) | null;
	onNFViewAsHTMLClick?: ((id: string, invoiceNumber?: string) => void) | null;
};

const useColumns = ({
	isDetailed = false,
	onApportionmentClick = null,
	onDetailsClick = null,
	columnsVisibility,
	optionalColumnsVisibility,
	apportionAction = false,
	onNFDownloadClick = null,
	onNFViewAsHTMLClick = null,
}: UseColumnsOptions): ColumnData => {
	const createColumnsWithStatusAndActions = (
		statusColumn: GridColDef,
		actionsColumn: GridColDef,
	): GridColDef[] => {
		const newColumns = [...defaultColumns];
		const allVisibleAndOptionalColumns = new Set(
			[...columnsVisibility, ...(optionalColumnsVisibility || []),
			],
		);
		const filteredNewColumns = optionalColumnsVisibility
			? newColumns.filter((column) => allVisibleAndOptionalColumns.has(column.field))
			: newColumns;
		const desiredIndex = filteredNewColumns.findIndex((col) => col.field === 'nrOrder') + 1;
		filteredNewColumns.splice(desiredIndex, 0, statusColumn);
		filteredNewColumns.push(actionsColumn);

		return filteredNewColumns;
	};

	const columns: GridColDef[] = useMemo(() => {
		const statusColumn: GridColDef = {
			type: 'number',
			field: 'status',
			headerName: 'Status',
			valueFormatter: (params: GridValueFormatterParams) => {
				let status = purchaseOrderApprovalStatusDetailed[params.value];

				if (!isDetailed) {
					if (params.value === 0) {
						status = 'Ativo';
					} else if (params.value === 3 || params.value === 4) {
						status = 'Estouro';
					}
				}

				return status;
			},
			flex: 0.4,
			sortable: false,
			align: 'center',
			renderCell: (params: GridRenderCellParams) => (
				<TruncateTooltipCell value={params.formattedValue} />
			),
		};

		const actionsColumn: GridColDef = {
			field: 'actions',
			headerName: 'Ações',
			width: 140,
			sortable: false,
			renderCell: (params: GridRenderCellParams<string>) => (
				<>
					{apportionAction && (
						<Tooltip title="Ratear">
							<Link to={params.id.toString()}>
								<GridActionsCellItem
									icon={<BackupTableIcon />}
									label="Ratear"
									className="textPrimary"
									color="primary"
								/>
							</Link>
						</Tooltip>
					)}
					{onNFViewAsHTMLClick && (
						<Tooltip title="Visualizar DANFE">
							<GridActionsCellItem
								onClick={() => onNFViewAsHTMLClick(params.id.toString(), params.row.invoiceNumber)}
								icon={<ReceiptIcon />}
								label="Visualizar DANFE"
								className="textPrimary"
								color="primary"
							/>
						</Tooltip>
					)}
					{onNFDownloadClick && (
						<Tooltip title="Baixar XML">
							<GridActionsCellItem
								onClick={() => onNFDownloadClick(params.id.toString(), params.row.invoiceNumber)}
								icon={<SimCardDownloadIcon />}
								label="Baixar XML"
								className="textPrimary"
								color="primary"
							/>
						</Tooltip>
					)}
					{onApportionmentClick && (
						<Tooltip title="Rateios">
							<IconButton onClick={() => onApportionmentClick(params.id.toString())}>
								<WorkspacesIcon />
							</IconButton>
						</Tooltip>
					)}
					{onDetailsClick && (
						<Tooltip title="Detalhes">
							<IconButton onClick={() => onDetailsClick(params.id.toString())}>
								<InfoIcon />
							</IconButton>
						</Tooltip>
					)}
				</>
			),
		};

		return createColumnsWithStatusAndActions(statusColumn, actionsColumn);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		apportionAction,
		isDetailed,
		onApportionmentClick,
		onDetailsClick,
		onNFDownloadClick,
	]);

	const defaultVisibility: GridColumnVisibilityModel = useMemo(
		() => columns.reduce((visibility, column) => ({
			...visibility,
			[column.field]: columnsVisibility.includes(column.field),
		}), {} as GridColumnVisibilityModel),
		[columns, columnsVisibility],
	);

	return { columns, defaultVisibility };
};

export default useColumns;

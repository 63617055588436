import React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { Outlet } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';

import Navbar from './Navbar';
import DrawerHeader from './DrawerHeader';

const sxContainer: SxProps<Theme> = {
	width: '100%',
	height: '100%',
	overflow: 'hidden',
};

const sxContent: SxProps<Theme> = {
	width: '100%',
	height: '100%',
	overflowX: 'hidden',
	p: '1rem',
};

const DefaultLayout = (): JSX.Element => (
	<Box sx={{ display: 'flex', height: '100%' }}>
		<CssBaseline />
		<Navbar />
		<Stack sx={sxContainer}>
			<DrawerHeader />
			<Stack sx={sxContent}>
				<Breadcrumb />
				<Outlet />
			</Stack>
		</Stack>
	</Box>
);

export default DefaultLayout;

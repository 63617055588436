export const cpfMask = '000.000.000-00';

export const cnpjMask = '00.000.000/0000-00';

export const phoneMask = '(00) 0 0000-0000';

export const newPhoneMask = '(00) 0000-0000';

export const cepMask = '00000-000';

export const removeMask = (value: string): string => value.replace(/[.-\s()/]/g, '');

const MASKS = {
	CEP: 8,
	NEW_PHONE: 10,
	CPF: 11,
	CNPJ: 14,
};

export const applyCnpjMask = (value: string): string => {
	const cnpjPattern = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;
	return value.replace(cnpjPattern, '$1.$2.$3/$4-$5');
};

export const applyCpfMask = (value: string): string => {
	const cpfPattern = /(\d{3})(\d{3})(\d{3})(\d{2})/;
	return value.replace(cpfPattern, '$1.$2.$3-$4');
};

export const applyPhoneMask = (value: string): string => {
	const phonePattern = /(\d{2})(\d{1})(\d{4})(\d{4})/;
	return value.replace(phonePattern, '($1) $2 $3-$4');
};

export const applyNewPhoneMask = (value: string): string => {
	const newPhonePattern = /(\d{2})(\d{4})(\d{4})/;
	return value.replace(newPhonePattern, '($1) $2-$3');
};

export const applyCepMask = (value: string): string => {
	const cepPattern = /(\d{5})(\d{3})/;
	return value.replace(cepPattern, '$1-$2');
};

export const applyAppropriateMask = (value: string): string => {
	const onlyDigits = removeMask(value);

	switch (onlyDigits.length) {
		case MASKS.CEP:
			return applyCepMask(onlyDigits);
		case MASKS.NEW_PHONE:
			return applyNewPhoneMask(onlyDigits);
		case MASKS.CPF:
			return applyCpfMask(onlyDigits);
		case MASKS.CNPJ:
			return applyCnpjMask(onlyDigits);
		default:
			return value;
	}
};

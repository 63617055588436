import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import Decimal from 'decimal.js';
import {
	DataGrid,
	GridColDef,
	GridColumnVisibilityModel,
	GridSelectionModel,
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import Tooltip from '@mui/material/Tooltip';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SxProps } from '@mui/system';
import Button from '@mui/material/Button';
import NoDataPage from '../../Common/NoDataPage';
import { currencyBRLMask } from '../../../helpers/intl';
import useColumns from '../../../hooks/useColumnsPurchaseOrder';
import { useManageColumns } from '../../../hooks/useManageColumns';
import { ToolbarComponent } from '../../Common/Datagrid/DataGridToolBar';
import ActionJustificativeModal from '../Modals/ActionJustificativeModal';
import { BudgetApprover, IPurchasesWithJustificative } from '../../../interfaces/PurchaseOrderApproval';
import { UserType } from '../../../containers/Order/PurchaseOrderApproval';
import useExpandAccordions from '../../../hooks/useExpandAccordions';
import { PurchaseOrderApprovalStatus } from '../../../enums/PurchaseOrderApprovalStatus';

const budgetContainer: SxProps<Theme> = {
	width: '100%',
	display: 'flex',
	my: '10px',
	justifyContent: 'space-between',
};

const budgetInfo: SxProps<Theme> = {
	display: 'flex',
	flexDirection: 'column',
	gap: '5px',
	flex: '1 1',
};

const buttons: SxProps<Theme> = {
	display: 'flex',
	gap: '1rem',
	mt: '10px',
};

const localStorageKey = 'purchaseOrdersColumns';

const columnsVisibility = [
	'nrOrder',
	'status',
	'providerName',
	'productDescription',
	'quantity',
	'unitValue',
	'totalValue',
	'lastPurchaseValue',
	'observation',
	'actions',
];

interface ManagePurchaseOrdersTableProps {
	rows: any[];
	userType: UserType;
	expandAccordions: boolean;
	selectAllRows?: boolean;
	onApportionmentClick(id: string): void;
	onDetailsClick(id: string): void;
	reactivatePurchase(data: IPurchasesWithJustificative): void;
}

interface DataGridConnectedProps {
	id: string;
	rows: any[];
	selectAllRows?: boolean;
	currentColumns: GridColDef[];
	onSelect(params: { id: string, rows: any }): void;
	columnVisibilityModel: GridColumnVisibilityModel;
	handleColumnVisibilityModelChange(model: GridColumnVisibilityModel): void;
	onRestoreClick: () => void;
}

const DataGridConnected = ({
	id,
	rows,
	selectAllRows,
	onSelect,
	currentColumns,
	handleColumnVisibilityModelChange,
	columnVisibilityModel,
	onRestoreClick,
}: DataGridConnectedProps): JSX.Element => {
	const [selectedRows, setSelectedRows] = useState<GridSelectionModel>([]);

	useEffect(() => {
		if (selectAllRows) {
			const allRowIds = rows.map((row) => row.id);
			setSelectedRows(allRowIds);
			onSelect({ id, rows: allRowIds });
		} else {
			setSelectedRows([]);
			onSelect({ id, rows: [] });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectAllRows]);

	const onSelectRows = useCallback(
		(selectionModel: GridSelectionModel) => {
			setSelectedRows(selectionModel);
			onSelect({ id, rows: selectionModel });
		},
		[id, onSelect],
	);

	return (
		<DataGrid
			rows={rows}
			columns={currentColumns}
			hideFooter
			checkboxSelection
			onSelectionModelChange={onSelectRows}
			selectionModel={selectedRows}
			disableSelectionOnClick
			disableColumnMenu
			autoHeight
			columnVisibilityModel={columnVisibilityModel}
			onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
			components={{ Toolbar: ToolbarComponent(onRestoreClick) }}
		/>
	);
};

DataGridConnected.defaultProps = {
	selectAllRows: false,
};

interface ReactivatePurchaseButtonProps {
	id: string;
	userType: UserType;
	disabled: boolean;
	reactivatePurchase(id: string, actionJustificative: string): void;
}

const ReactivatePurchaseButton = ({
	id, userType, disabled, reactivatePurchase,
}: ReactivatePurchaseButtonProps): JSX.Element => {
	const [dialogOpen, setDialogOpen] = useState(false);

	return (
		<Box sx={buttons}>
			{userType !== UserType.RESPONSIBLE && (
				<>
					<Button
						variant="contained"
						color="error"
						fullWidth
						disabled={disabled}
						onClick={() => setDialogOpen(true)}
					>
						Desfazer Rejeitados
					</Button>
					<ActionJustificativeModal
						id={id}
						title="Desfazer Rejeitados"
						action={reactivatePurchase}
						open={dialogOpen}
						onClose={() => setDialogOpen(false)}
					/>
				</>
			)}
		</Box>
	);
};

const ManagePurchaseOrdersTable = ({
	rows,
	userType,
	selectAllRows,
	expandAccordions,
	onApportionmentClick,
	onDetailsClick,
	reactivatePurchase,
}: ManagePurchaseOrdersTableProps): JSX.Element => {
	const [selectedRows, setSelectedRows] = useState<{ id: string; rows: Array<string> }[]>([]);
	const { expandPanels, togglePanel } = useExpandAccordions(rows, expandAccordions);

	const { columns, defaultVisibility } = useColumns({
		isDetailed: true,
		onApportionmentClick,
		onDetailsClick,
		columnsVisibility,
	});

	const {
		currentColumns,
		columnVisibilityModel,
		handleColumnVisibilityModelChange,
		onRestoreClick,
	} = useManageColumns({
		initialColumns: columns,
		initialVisibility: defaultVisibility,
		localStorageKey,
		isDraggable: true,
	});

	const accountingAccountTooltip = useCallback((budgetApprovers: BudgetApprover[]) => {
		if (budgetApprovers.length === 0) {
			return <span>Não há aprovadores cadastrados</span>;
		}

		return (
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				{budgetApprovers.map((approver) => (
					<span key={approver.user.name}>{approver.user.name}</span>
				))}
			</Box>
		);
	}, []);

	const handleAccordionChange = useCallback(
		(panelId: string) => () => {
			togglePanel(panelId);
		},
		[togglePanel],
	);

	const onSelect = useCallback(
		(tableRow) => {
			setSelectedRows((currentSelectedRows) => {
				const newSelectedRows = [...currentSelectedRows];
				const selectedRow = newSelectedRows.find((row) => row.id === tableRow.id);

				if (selectedRow) {
					selectedRow.rows = tableRow.rows;
				} else {
					newSelectedRows.push(tableRow);
				}

				return newSelectedRows;
			});
		},
		[],
	);

	const hasSelectedRows = useCallback(
		(id: string) => selectedRows.some((row) => row.id === id && row.rows && row.rows.length > 0),
		[selectedRows],
	);

	const reactivatePurchaseProxy = useCallback(
		(id: string, actionJustificative: string) => {
			const selectedRow = selectedRows.find((row) => row.id === id);

			if (selectedRow && selectedRow.rows.length > 0) {
				const reactivatePurchaseData: IPurchasesWithJustificative = {
					ids: selectedRow.rows,
					actionJustificative,
				};
				reactivatePurchase(reactivatePurchaseData);
			}
		},
		[reactivatePurchase, selectedRows],
	);

	const reactivateSelectedRows = useCallback(
		(id) => selectedRows.some((selectedRow) => selectedRow.id === id
			&& selectedRow.rows.every((rowId) => {
				const purchaseOrderApproval = rows.find(
					(row) => row.id === id,
				)?.purchaseOrderApprovals.find((poa: { id: string; }) => poa.id === rowId);

				return purchaseOrderApproval?.status === PurchaseOrderApprovalStatus.REJECTED;
			})),
		[selectedRows, rows],
	);

	const content = useMemo(() => {
		if (!rows || (rows && rows.length === 0)) {
			return <NoDataPage />;
		}

		return (
			rows
			&& rows.map((row) => {
				const inProcessValue = row.totalValue.minus(row.commitedValue).minus(row.realizedValue);
				const totalPurchaseValue = row.purchaseOrderApprovals.reduce(
					(total: Decimal, purchase: any) => total.plus(purchase.totalValue),
					new Decimal(0),
				);

				return (
					<Accordion
						key={row.id}
						expanded={expandPanels.includes(row.id)}
						onChange={handleAccordionChange(row.id)}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls={`${row.id}-content`}
							id={`${row.id}-header`}
						>
							<Typography
								sx={{
									width: '10%',
									flexShrink: 0,
								}}
							>
								{`${row.code}`}
							</Typography>
							<Typography
								sx={{
									width: '60%',
									flexShrink: 0,
									color: 'text.secondary',
								}}
							>
								<Tooltip title={accountingAccountTooltip(row.accountingAccount.budgetApprovers)}>
									<span>{`Cta. Contábil: ${row.accountingAccount.name}`}</span>
								</Tooltip>
								{`- C. Custo: ${row.costCenter.name} - Filial: ${row.accountingItem.name} - CliFor.: ${row.classValue.name}`}
							</Typography>
							<Typography
								sx={{
									color: 'text.primary',
								}}
							>
								{`Total a aprovar: ${currencyBRLMask(totalPurchaseValue)}`}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<React.Fragment key={row.id}>
								<Box sx={budgetContainer}>
									<Box sx={budgetInfo}>
										<span>(O)rçado</span>
										<span>{currencyBRLMask(row.totalValue)}</span>
									</Box>
									<Box sx={budgetInfo}>
										<span>(R)ealizado</span>
										<span>{currencyBRLMask(row.realizedValue)}</span>
									</Box>
									<Box sx={budgetInfo}>
										<span>(A)provado</span>
										<Box sx={{ display: 'flex' }}>
											<Typography
												sx={{ color: 'text.primary' }}
											>
												{currencyBRLMask(row.commitedValue)}
											</Typography>
										</Box>
									</Box>
									<Box sx={budgetInfo}>
										<span>Saldo em processo</span>
										<span>
											<Tooltip title="Orçado - Aprovado - Realizado">
												<span>(O-A-R) =</span>
											</Tooltip>
											{' '}
											{currencyBRLMask(inProcessValue)}
										</span>
									</Box>
									<Box sx={budgetInfo}>
										<span>Saldo Realizado</span>
										<span>
											<Tooltip title="Orçado - Realizado">
												<span>(O-R) =</span>
											</Tooltip>
											{' '}
											{currencyBRLMask(row.totalValue.minus(row.realizedValue))}
										</span>
									</Box>
								</Box>
								<DataGridConnected
									id={row.id}
									rows={row.purchaseOrderApprovals}
									onSelect={onSelect}
									currentColumns={currentColumns}
									selectAllRows={selectAllRows}
									columnVisibilityModel={columnVisibilityModel}
									handleColumnVisibilityModelChange={handleColumnVisibilityModelChange}
									onRestoreClick={onRestoreClick}
								/>
							</React.Fragment>
							<ReactivatePurchaseButton
								id={row.id}
								userType={userType}
								disabled={!hasSelectedRows(row.id) || !reactivateSelectedRows(row.id)}
								reactivatePurchase={reactivatePurchaseProxy}
							/>
						</AccordionDetails>
					</Accordion>
				);
			})
		);
	}, [
		rows,
		accountingAccountTooltip,
		onSelect,
		currentColumns,
		columnVisibilityModel,
		handleColumnVisibilityModelChange,
		onRestoreClick,
		userType,
		hasSelectedRows,
		reactivatePurchaseProxy,
		reactivateSelectedRows,
		expandPanels,
		handleAccordionChange,
		selectAllRows,
	]);

	const footer = useMemo(() => {
		const purchases: any[] = [];

		if (!rows || (rows && rows.length === 0)) {
			return null;
		}

		rows.forEach((row) => {
			row.purchaseOrderApprovals.forEach((purchase: any) => {
				const purchaseIndex = purchases.findIndex(
					(provider) => provider.id === purchase.providerCode,
				);

				if (purchaseIndex > -1) {
					purchases[purchaseIndex].name = purchase.providerName;
					purchases[purchaseIndex].total = purchases[purchaseIndex].total.plus(purchase.totalValue);
				} else {
					purchases.push({
						id: purchase.providerCode,
						name: purchase.providerName,
						total: new Decimal(purchase.totalValue),
					});
				}
			});
		});

		const total = purchases.reduce(
			(currentTotal: Decimal, provider: any) => currentTotal.plus(provider.total),
			new Decimal(0),
		);

		return (
			<Accordion>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="footer-content"
					id="footer-header"
				>
					<Typography sx={{ width: '33%', flexShrink: 0 }}>
						Total por Fornecedor
					</Typography>
					<Typography sx={{ color: 'text.secondary' }}>
						Total:
						{' '}
						{currencyBRLMask(total)}
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					{purchases.map((purchase) => (
						<Box key={purchase.id}>
							<Typography>
								{`${purchase.name}: ${currencyBRLMask(purchase.total)}`}
							</Typography>
						</Box>
					))}
				</AccordionDetails>
			</Accordion>
		);
	}, [rows]);

	return (
		<>
			{content}
			{footer}
		</>
	);
};

ManagePurchaseOrdersTable.defaultProps = {
	selectAllRows: false,
};

export default ManagePurchaseOrdersTable;

import React, { useEffect, useState } from 'react';
import { ptBR } from '@mui/x-data-grid';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptLocale from 'date-fns/locale/pt';
import Routes from './Routes';
import './App.scss';
import { getConfiguration, getName } from './services/app';
import { DEFAULT_NAME } from './constants/general';

/*
Cores Mais i9

color: #363636; --text
color: #1c447f; --primary
color: #59889c; --primary less
color: #eb922c; -- secondary

*/

const theme = createTheme({
	palette: {
		background: {
			default: '#fafafa',
		},
		primary: {
			main: '#1c447f',
		},
		secondary: {
			main: '#eb922c',
		},
		success: {
			main: '#43a047',
		},
		info: {
			main: '#2196f3',
		},
		error: {
			main: '#d32f2f',
		},
		warning: {
			main: '#ff9800',
		},
		text: {
			primary: '#363636',
		},
	},
}, ptBR);

const App = (): JSX.Element => {
	const [name, setName] = useState(getName());

	useEffect(() => {
		getConfiguration().then((response) => {
			const newName = response.data.name || DEFAULT_NAME;

			localStorage.setItem('name', newName);
			setName(newName);
		});
	}, []);

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptLocale}>
			<ThemeProvider theme={theme}>
				<StyledEngineProvider injectFirst>
					<SnackbarProvider maxSnack={3}>
						<BrowserRouter>
							<Routes name={name} />
						</BrowserRouter>
					</SnackbarProvider>
				</StyledEngineProvider>
			</ThemeProvider>
		</LocalizationProvider>
	);
};

export default App;

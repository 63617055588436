import React, {
	useCallback, useEffect, useMemo, useState,
} from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import UserTable from './UserTable';
import { ResetPasswordDrawer } from './ResetPasswordDrawer';
import { CurrentUserProps, User } from '../../containers/User/UserAssets';
import { UserQueryParams } from '../../interfaces/UserQueryParams';
import { PageHeader } from '../Common/PageHeader/PageHeader';
import { PageHeaderButtonProps } from '../../interfaces/PageHeaderInterface';
import UserFilter from './UserFilter';
import { Loading } from '../Common/Loading';
import { normalizeDataGridOrderBy } from '../../helpers/Utils';

interface UserProps {
	loading: boolean;
	users: User[];
	usersPages: number;
	usersPage: number;
	usersTake: number;
	getUsers(queryParams: UserQueryParams): void;
	changePassword(id: string, password: string, changePasswordLogin: boolean): void;
	handleUserActiveStatus(id: string, active: boolean): void;
}

const headerButtonsProps: PageHeaderButtonProps[] = [
	{
		variant: 'contained',
		color: 'primary',
		type: 'submit',
		fullWidth: true,
		component: Link,
		to: 'edit',
		text: 'Adicionar',
	},
];

const UserComponent = ({
	loading,
	users,
	usersPages,
	usersPage,
	usersTake,
	getUsers,
	changePassword,
	handleUserActiveStatus,
}: UserProps): JSX.Element => {
	const [openReset, setOpenReset] = useState<CurrentUserProps | undefined>(undefined);
	const [filter, setFilter] = useState({});
	const [sortModel, setSortModel] = React.useState<GridSortModel>([
		{
			field: 'name',
			sort: 'asc',
		},
	]);

	useEffect(() => {
		getUsers({ skip: 0 });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCloseReset = useCallback(() => {
		setOpenReset(undefined);
	}, []);

	const handleResetPassword = useCallback((user: CurrentUserProps) => {
		setOpenReset(user);
	}, []);

	const onChangePage = useCallback((page) => {
		const pageFilter = {
			...filter,
			skip: page,
			take: usersTake,
			orderBy: normalizeDataGridOrderBy(sortModel) as { name: GridSortDirection },
		};

		getUsers(pageFilter);
	}, [filter, getUsers, sortModel, usersTake]);

	const onChangePageSize = useCallback((pageSize) => {
		const pageFilter = {
			...filter,
			skip: usersPage,
			take: pageSize,
			orderBy: normalizeDataGridOrderBy(sortModel) as { name: GridSortDirection },
		};

		getUsers(pageFilter);
	}, [filter, getUsers, sortModel, usersPage]);

	const sendFilter = useCallback((values: Partial<UserQueryParams>) => {
		const pageFilter = {
			...values,
			skip: 0,
			orderBy: normalizeDataGridOrderBy(sortModel) as { name: GridSortDirection },
		};

		getUsers(pageFilter);
		setFilter(pageFilter);
	}, [getUsers, sortModel]);

	const handleSortModelChange = useCallback((newSortModel: GridSortModel) => {
		const pageFilter = {
			...filter,
			skip: 0,
			orderBy: normalizeDataGridOrderBy(newSortModel) as { name: GridSortDirection },
		};

		getUsers(pageFilter);
		setSortModel(newSortModel);
	}, [getUsers, filter]);

	const pageHeaderMemo = useMemo(() => <PageHeader title="Usuário" buttons={headerButtonsProps} />, []);

	const filterMemo = useMemo(() => (
		<UserFilter
			sendFilter={sendFilter}
		/>
	), [sendFilter]);

	return (
		<Box className="content">
			{pageHeaderMemo}
			{filterMemo}
			{loading && <Loading />}
			{!loading && (
				<UserTable
					loading={loading}
					rows={users}
					usersPages={usersPages}
					usersPage={usersPage}
					usersTake={usersTake}
					sortModel={sortModel}
					onChangePageSize={onChangePageSize}
					onChangePage={onChangePage}
					onSortModelChange={handleSortModelChange}
					onResetPassword={handleResetPassword}
					onChangeActiveStatus={handleUserActiveStatus}
				/>
			)}
			<ResetPasswordDrawer
				openReset={openReset}
				handleClose={handleCloseReset}
				changePassword={changePassword}
			/>
		</Box>
	);
};

export default UserComponent;

import * as Yup from 'yup';
import { validationMessage } from '../../helpers/yupMessages';

export interface User {
	id: string;
	login: string;
	email: string;
	password?: string;
	name: string;
	phone: string;
	createdAt: string;
	changePasswordLogin: boolean;
	photo?: string;
	updatedAt?: string;
	active: boolean;
}

export interface AddUser {
	name: string;
	email: string;
	phone: string;
	login: string;
	changePasswordLogin: boolean;
}

export interface EditUser {
	name: string;
	email: string;
	phone: string;
}

export interface CurrentUserProps {
	id: string;
	name: string;
}

export const initialValuesAddUser = {
	name: '',
	email: '',
	phone: '',
	login: '',
	password: '',
	changePasswordLogin: true,
};

export const addUserValidationSchema = Yup.object().shape({
	name: Yup.string().required(validationMessage.required),
	email: Yup.string()
		.email(validationMessage.string.email)
		.required(validationMessage.required),
	phone: Yup.string()
		.min(10, validationMessage.string.min(15))
		.max(16, validationMessage.string.max(16))
		.required(validationMessage.required),
	login: Yup.string().required(validationMessage.required),
	password: Yup.string().required(validationMessage.required),
});

export const editUserValidationSchema = Yup.object().shape({
	name: Yup.string().required(validationMessage.required),
	email: Yup.string()
		.email(validationMessage.string.email)
		.required(validationMessage.required),
	phone: Yup.string()
		.min(10, validationMessage.string.min(15))
		.max(16, validationMessage.string.max(16))
		.required(validationMessage.required),
});

import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Input from '../../Common/Form/Input';
import SwitchField from '../../Common/Form/Switch';
import { newPhoneMask, phoneMask } from '../../../helpers/masks';

export const FormTitle = ({ children }: { children: string }): JSX.Element => (
	<Box>
		<Typography variant="h4" display="block" color="primary" gutterBottom>
			{children}
		</Typography>
		<Typography variant="caption">
			*Obrigatório
		</Typography>
	</Box>
);

export const SectionTitle = ({ children }: { children: string }): JSX.Element => (
	<Grid item xs={12}>
		<Typography variant="h6" display="block" color="primary" gutterBottom>
			{children}
		</Typography>
	</Grid>
);

export const PersonalData = (): JSX.Element => (
	<Box>
		<Grid container spacing={2}>
			<SectionTitle>Dados pessoais</SectionTitle>

			<Grid item xs={4}>
				<Input.InputField
					id="name"
					name="name"
					label="Nome"
					autoComplete="off"
					required
				/>
			</Grid>

			<Grid item xs={4}>
				<Input.InputField
					id="email"
					name="email"
					label="Email"
					autoComplete="off"
					required
				/>
			</Grid>

			<Grid item xs={4}>
				<Input.InputMaskField
					id="phone"
					name="phone"
					label="Telefone"
					autoComplete="off"
					mask={[{ mask: newPhoneMask, maxLength: 10 }, { mask: phoneMask, maxLength: 11 }]}
					required
				/>
			</Grid>
		</Grid>
	</Box>
);

export const LoginConfig = (): JSX.Element => (
	<Box>
		<Grid container spacing={2} alignItems="center">
			<SectionTitle>Configurações de login</SectionTitle>

			<Grid item xs={4}>
				<Input.InputField
					id="login"
					name="login"
					label="Nome de usuário"
					autoComplete="off"
					required
				/>
			</Grid>

			<Grid item xs={4}>
				<Input.PasswordInputField
					id="password"
					name="password"
					label="Senha"
					margin="none"
					autoComplete="new-password"
					required
				/>
			</Grid>

			<Grid item xs={4}>
				<SwitchField
					name="changePasswordLogin"
					label="Alterar senha no primeiro acesso"
				/>
			</Grid>
		</Grid>
	</Box>
);

import React, { useMemo } from 'react';
import {
	Box,
	Theme,
	Card,
	CardActionArea,
	CardContent,
	Typography,
	Stack,
	Icon,
} from '@mui/material';
import PieChartIcon from '@mui/icons-material/PieChart';
import { SxProps } from '@mui/system';
import { Link } from 'react-router-dom';
import { getUserData } from '../../helpers/getUserData';
import { getMenu } from '../../services/app';

interface HomeProps {
	loading?: boolean;
	components?: any[];
}

const homeContainer: SxProps<Theme> = {
	width: '100%',
	display: 'flex',
	justifyContent: 'space-around',
	flexWrap: 'wrap',
	gap: '1rem 1rem',
};

const actionContent: SxProps<Theme> = {
	height: '100%',
};

const content: SxProps<Theme> = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	rowGap: '2rem',
	width: 200,
	p: 3,
};

const Home = (): JSX.Element => {
	const modulesCard = useMemo(() => getMenu().map((module) => (
		<Card key={module.code}>
			<CardActionArea component={Link} to={module.link} sx={actionContent}>
				<CardContent sx={content}>
					<Icon
						fontSize="large"
						color="primary"
						style={{ fontSize: '5rem' }}
					>
						{module.icon}
					</Icon>
					<Typography
						gutterBottom
						variant="h5"
						component="span"
						textAlign="center"
					>
						{module.name}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	)), []);

	return (
		<Stack spacing="4rem" sx={{ mt: 2 }}>
			<Typography variant="h4" component="h1">
				Bem vindo(a),
				{' '}
				{getUserData()?.firstName}
				!
			</Typography>
			<Box sx={homeContainer}>
				{modulesCard}
				<Card>
					<CardActionArea component={Link} to="/order/apportionment" sx={actionContent}>
						<CardContent sx={content}>
							<PieChartIcon
								color="primary"
								style={{ fontSize: '5rem' }}
							/>
							<Typography
								gutterBottom
								variant="h5"
								component="span"
								textAlign="center"
							>
								Gerenciar rateios
							</Typography>
						</CardContent>
					</CardActionArea>
				</Card>
			</Box>
		</Stack>
	);
};

export default Home;
